<template>
  <div class="fullscreen">
    <img :src="imageSrc" alt="City Status Image">
  </div>
</template>

<script>
import axios from 'axios';

export default {
data() {
    return {
        estagio: '',
    };
},
computed: {
    imageSrc() {
        let estagio = this.estagio;
        estagio = estagio.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        switch (estagio) {
            case 'estagio 1':
                return require('@/assets/estagio_1.png');
            case 'estagio 2':
                return require('@/assets/estagio_2.png');
            case 'estagio 3':
                return require('@/assets/estagio_3.png');
            case 'estagio 4':
                return require('@/assets/estagio_4.png');
            case 'estagio 5':
                return require('@/assets/estagio_5.png');
            default:
                return '';
        }
    },
},
methods: {
    fetchData() {
        console.log("Updating data...")
        axios.get('https://aplicativo.cocr.com.br/estagio_api')
            .then(response => {
                this.estagio = response.data.estagio;
            })
            .catch(error => {
                console.error(error);
            });
    },
},
mounted() {
    this.fetchData();
    setInterval(() => {
        this.fetchData();
    }, 5 * 60 * 1000); // Refresh every 5 minutes (300000 milliseconds)
},
};
</script>

<style>
  body {
    margin: 0;
    padding: 0;
  }
  .fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .fullscreen img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>
